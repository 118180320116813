import {TextField, Button, Stack, FormControl, FormGroup} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';

import React, { Component } from 'react'

export class FormSubmit extends Component {

    constructor() {
        super()

        this.state = {
            inputMessage: "",
            aboutYouMessage: "",
            aboutOtherMessage: "",
            outputMessage: "",
            isActive: false
        }
    }

    handleInputChange = (event) => {
        this.setState({
            inputMessage: event.target.value
        }, console.log(this.state.inputMessage))
    }

    handleAboutYouChange = (event) => {
        this.setState({
            aboutYouMessage: event.target.value
        })
    }

    handleAboutOtherChange = (event) => {
        this.setState({
            aboutOtherMessage: event.target.value
        })
    }

    handleSubmit = async (event) => {

        if(this.state.inputMessage !== "") {
            event.preventDefault()

            this.setState({
                isActive: true,
            })
            try {

                const output = await this.getResponse("test")

                this.setState({
                    isActive: false,
                    outputMessage: output.trim()
                })

            } catch (e) {
                console.log(e)
                this.setState({
                    isActive: false,
                    outputMessage: "Error encountered"
                })
            }
        } 


    }

    handleResetButton = (event) => {
        this.setState({
            inputMessage: "",
            aboutYouMessage: "",
            aboutOtherMessage: "",
            outputMessage: ""
        }, console.log(`New input: ${this.state.inputMessage}`))
    }

    getResponse = async (prompt) => {
        var response = await fetch("https://chadgpt-api-production.up.railway.app/api", { 
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                },
            body: JSON.stringify(
            {
                "inputs": this.state,
                "settings": {
                    "temperature": this.props.temperature,
                    "max_tokens": this.props.max_tokens
                }
            }
            )
        
        })
        response = await response.json()
        console.log(response['prompt'])
        return response['prompt']
    }


    render() {
        return (
            <div className="">
                <form>
                    <FormGroup>
                        <Stack spacing={4}>

                            <FormControl>
                                <TextField className="my-2"
                                    id="outlined-multiline-static"
                                    label="Input"
                                    helperText="Enter a message to which you want to respond."
                                    multiline
                                    rows={2}
                                    required
                                    fullWidth
                                    onChange={this.handleInputChange}
                                    placeholder="E.g., Do you speak french?"
                                    value={this.state.inputMessage}
                                    InputLabelProps={{ shrink: true }}
                                    aria-describedby="my-helper-text" 
                                />
                            </FormControl>
                            <TextField className="my-10"
                                id="outlined-multiline-static"
                                label="About yourself"
                                helperText="Provide us with relevant information about yourself (optional)."
                                multiline
                                rows={2}
                                fullWidth
                                value={this.state.aboutYouMessage}
                                placeholder="E.g., I speak French"
                                onChange={this.handleAboutYouChange}
                                InputLabelProps={{ shrink: true }}
                            />
                            <TextField
                                id="outlined-multiline-static"
                                label="About Other Person"
                                helperText="Provide us with relevant information about the other person (optional)."
                                multiline
                                rows={2}
                                fullWidth
                                value={this.state.aboutOtherMessage}
                                onChange={this.handleAboutOtherChange}
                                InputLabelProps={{ shrink: true }}
                                placeholder="E.g., She likes puns"
                            />
                            <div className="flex flex-row align-center justify-center gap-2">
                                <i></i>
                                <Button type="submit" className="flex-1" variant="contained" onClick={this.handleSubmit}>
                                    <SettingsIcon className={this.state.isActive ? 'rotate' : ''}/>
                                    Generate Response
                                </Button>
                                <Button className="flex" variant="outlined" onClick={this.handleResetButton}>Reset</Button>
                            </div>
                            <TextField
                                id="filled-multiline-static"
                                label="Output"
                                helperText="A message generated by ChadGPT based on your input and information provided (see settings to adjust tone, number of words or randomness)."
                                multiline
                                rows={2}
                                variant="filled"
                                fullWidth
                                InputProps={{readOnly: true }}
                                value={this.state.outputMessage}
                                InputLabelProps={{ shrink: true }}
                                placeholder="E.g., Yes, Quoi's up?"
                            />              
                        </Stack>
                    </FormGroup>

                </form>
            </div>
        )
    }
}

export default FormSubmit;