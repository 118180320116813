import './App.css';
// import ButtonAppBar from './components/ButtonAppBar';
import { Container, Typography, AppBar, Box, CssBaseline, Stack, Toolbar, createTheme, ThemeProvider, Grid} from '@mui/material';
import React, {Component} from 'react'

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormSubmit from './components/FormSubmit';

import NonLinearSlider from './components/NonLinearSlider';

class App extends Component {

  constructor(props) {
    super(props)
  
    this.state = {
        "temperature": 50,
        "max_tokens": 50
    }
  }s

  updateTemperature = (temp) => {
    this.setState({ 
      temperature: temp
    }, () => console.log(this.state.temperature))
  }

  updateMaxTokens = (tokens) => {
    this.setState({ 
      max_tokens: tokens
    }, () => console.log(this.state.max_tokens))
  }
  

  theme = createTheme({
    palette: {
      primary: {
        main: '#FD3A73',
        // light: '#FD3A73',
        // dark: '#FD3A73'
      }
    },
    typography: {
      h5: {
        fontWeight: 600, // or 'bold'
        fontFamily: "Futura"
      }
    },

    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            // fontSize: '1rem',
            // backgroundColor: "grey",
            "& input::placeholder": {
              fontStyle: "bold"
            }
          }
        }
      }
    }
  })


  render() {
  return (
      <ThemeProvider theme={this.theme}>
        <CssBaseline/>
        <Stack className="h-screen p-0 m-0" direction="column">
          <Box className="">
            <AppBar position="relative">
              <Toolbar>
                  <Typography variant="h5" component="div" sx={{ flexGrow: 1 }}>
                    ChadGPT
                  </Typography>
                  {/* <MuiDrawer title="Login">
                  </MuiDrawer>
                  <MuiDrawer title="Signup">
                    <SignUp/>
                  </MuiDrawer> */}
              </Toolbar>
            </AppBar>
          </Box>
          <Container maxWidth="xl" className="my-6">
            <Grid container spacing={4} className="h-full">
              <Grid item xs={12} md={8} className="">
                <Stack spacing={2}>
                  <Typography variant="h5" align="left" color="textPrimary">
                    Revolutionize Your Tinder Game with ChadGPT - Your Ultimate Wingman for Flirty Conversations!
                  </Typography>
                  <FormSubmit temperature={this.state.temperature} max_tokens={this.state.max_tokens}/>
                </Stack>

              </Grid>
              <Grid item xs={12} md={4} className="">
                
                <Stack spacing={2}>
                  <Typography variant="h5" align="left" color="textPrimary" className="">
                    Settings
                  </Typography>

                  <Box className="h-full">
                    <FormControl  fullWidth className="">
                      <FormLabel id="demo-radio-buttons-group-label" className=" w-100">Tone</FormLabel>
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="Funny"
                        name="radio-buttons-group"
                        className="px-2"
                        >
                        <FormControlLabel value="Flirty" control={<Radio />} label="Flirty"/>
                        <FormControlLabel value="Funny" control={<Radio />} label="Funny"  />
                        <FormControlLabel value="Western" control={<Radio />} label="Western" />
                        <FormControlLabel value="Spicy" control={<Radio />} label="Spicy" />
                      </RadioGroup>


                      <NonLinearSlider label="Randomness" updateTemperature={this.updateTemperature}></NonLinearSlider>
                      <NonLinearSlider label="Number of Words" updateMaxTokens={this.updateMaxTokens}></NonLinearSlider>
                    </FormControl> {/*  */}                 
                  </Box>

                </Stack>

              </Grid>
            </Grid>
          </Container>
        </Stack>
    </ThemeProvider> 
  );
}
}

export default App;
