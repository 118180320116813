import * as React from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import { FormLabel } from '@mui/material';


// function calculateValue(value) {
//   return 2 ** value;
// }

export default function NonLinearSlider({label, updateMaxTokens, updateTemperature}) {
  const [value, setValue] = React.useState(50);

  const handleChange = (event, newValue) => {
    if (typeof newValue === 'number') {
      setValue(newValue);
    }

    if(label === "Randomness") {
      updateTemperature(newValue)
    } else if(label === "Number of Words") {
      updateMaxTokens(newValue)
    } else {
      console.log("ERROR: invalid label given to slider")
    }

  };

  return (
    <Box sx={{ width: "auto" }}>
      <div className="flex justify-between" >
        {/* <Typography id="non-linear-slider" gutterBottom> */}
        <FormLabel id={label}>{label}:</FormLabel> {value}
        {/* </Typography> */}
      </div>

      
      <Slider
        value={value}
        min={5}
        step={1}
        max={100}
        // scale={calculateValue}
        // getAriaValueText={valueLabelFormat}
        // valueLabelFormat={valueLabelFormat}
        onChange={handleChange}
        // valueLabelDisplay="auto"
        // aria-labelledby="non-linear-slider"
      />
    </Box>
  );
}